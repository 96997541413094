:root {
  --hs-padding-1: 0.813rem;
  --preview-component-container-big: 20rem;
  --preview-component-container-small: 15rem;
  --preview-container-height: 48px;
}

/* ############################## Font ############################## */
/* ::::::: Font size ::::::: */

.hs-fs-11 {
  font-size: 11px !important;
}

.hs-fs-12 {
  font-size: 12px !important;
}

.hs-fs-13 {
  font-size: 13px !important;
}

.hs-fs-14 {
  font-size: 14px !important;
}

.hs-fs-15 {
  font-size: 15px !important;
}

.hs-fs-16 {
  font-size: 16px !important;
}

.hs-fs-17 {
  font-size: 17px !important;
}

.hs-fs-18 {
  font-size: 18px !important;
}

.hs-fs-22 {
  font-size: 22px !important;
}
.hs-fs-24 {
  font-size: 24px !important;
}
.hs-fs-32 {
  font-size: 32px !important;
}
.hs-fs-40 {
  font-size: 40px !important;
}
/* ::::::: Font weight ::::::: */

.hs-fw-400 {
  font-weight: 400 !important;
}

.hs-fw-500 {
  font-weight: 500 !important;
}

.hs-fw-550 {
  font-weight: 550 !important;
}

.hs-fw-500 {
  font-weight: 500 !important;
}

.hs-fw-600 {
  font-weight: 600 !important;
}
.hs-fw-700 {
  font-weight: 700 !important;
}

.hs-fw-400 {
  font-weight: 400 !important;
}

.hs-fw-800 {
  font-weight: 800 !important;
}

/* ############################## COLORS ############################## */

.hs-color-violet {
  color: var(--hs-violet) !important;
}
.hs-border-violet {
  border-color: var(--hs-violet) !important;
}

.hs-color-mediumDark {
  color: var(--hs-color-medium-dark) !important;
}
.hs-color-BlackRussian {
  color: var(--hs-color-blackRussian) !important;
}
.hs-bg-v-light-grey {
  background-color: var(--hs-v-light-grey) !important;
}

.hs-bg-violet {
  background-color: var(--hs-violet) !important;
}

.hs-text-muted {
  color: rgba(129, 128, 148) !important;
}

.hs-bg-off-white {
  background-color: var(--hs-off-white) !important;
}
.hs-bg-GhostWhite {
  background-color: #f7f7fb;
}

.hs-bg-white {
  background-color: #fff !important;
}

.hs-color-dark-blue {
  color: var(--hs-dark-blue) !important;
}

.hs-bg-light-orange {
  background-color: var(--hs-light-orange);
}

.hs-color-dark-orange {
  color: var(--hs-dark-orange);
}

.hs-color-dark-grey {
  color: var(--hs-dark-grey);
}

.hs-color-light-grey-2 {
  color: var(--hs-light-grey-2);
}

.hs-color-light-blue-2 {
  color: var(--hs-light-blue-2);
}

.hs-color-red {
  color: var(--hs-red);
}
.hs-bg-red {
  background-color: var(--hs-red) !important;
}

.hs-bg-v-light-red {
  background-color: var(--hs-v-light-red);
}

.hs-color-light-silver {
  color: var(--hs-light-silver);
}

.hs-bg-light-silver {
  background-color: var(--hs-light-silver);
}

.hs-bg-dark-grey {
  background: var(--hs-dark-grey);
}

.hs-bg-light-grey {
  background-color: var(--hs-light-grey);
}

.hs-border-dark-grey {
  border: var(--hs-border-light);
}

.hs-outline-dark-grey {
  outline: var(--hs-border-dark);
}

.hs-border-right-light {
  border-right: var(--hs-border-light);
}
.hs-border-bottom-light {
  border-bottom: var(--hs-border-light);
}

.hs-bg-light-blue-3 {
  background-color: var(--hs-light-blue-3);
}

.hs-bg-tag-purple {
  background-color: #e9e1ff;
}

.hs-text-white-space-no-wrap {
  white-space: nowrap !important;
}

.all-unset {
  all: unset !important;
}

.hs-color-placeholder-grey {
  color: var(--hs-placeholder-grey) !important;
}

.hs-max-h-60 {
  max-height: 60% !important;
}
.hs-max-h-63 {
  max-height: 63% !important;
}

.hs-max-h-50 {
  max-height: 50% !important;
}
.hs-h-40-px {
  height: 40px !important;
}
.hs-w-40-px {
  width: 40px !important;
}

.hs-h-91-dvh {
  height: 91dvh !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}

.hs-hr {
  border-top: 0.9px solid #00001290;
}

.hs-w-fit {
  width: fit-content !important;
}

.hs-spin-360-infinite {
  animation: rotate-animation 1s cubic-bezier(0.25, 0.75, 0.75, 1) infinite;
}

@keyframes rotate-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ############################## BORDERS ############################## */

.hs-border-0-25-rem {
  border-radius: 0.25rem !important;
}

.hs-border-8 {
  border-radius: 8px !important;
}

.hs-border-10 {
  border-radius: 10px;
}

.hs-border-5 {
  border-radius: 5px !important;
}

.hs-border-12 {
  border-radius: 12px !important;
}
.hs-border-15 {
  border-radius: 15px !important;
}

.hs-border-20 {
  border-radius: 20px !important;
}

.hs-border-30 {
  border-radius: 30px !important;
}

/* ::::::: Custom border style ::::::: */

.hs-focus-border {
  border: none;
  transition: none;
}

.border-none,
.border-none:focus-visible {
  outline: none !important;
  border: none !important;
}

.border-none > :not(.profileAvatarBg) {
  visibility: hidden !important;
}

.hs-focus-border:focus-within {
  outline: 2px solid var(--hs-violet);
}

.hs-focus-border-red:focus-within {
  outline: 2px solid var(--hs-red) !important;
}

/* ############################## WIDTHS AND HEIGHTS ############################## */

.hs-mw-85 {
  max-width: 85% !important;
}
.hs-w-10 {
  width: 10% !important;
}
.hs-w-60 {
  width: 60% !important;
}
.hs-w-70 {
  width: 70% !important;
}
.hs-w-75 {
  width: 75% !important;
}

.hs-w-85 {
  width: 85% !important;
}
.hs-w-90 {
  width: 90% !important;
}

.hs-h-90 {
  height: 90% !important;
}
.hs-h-93 {
  height: 93% !important;
}

.hs-h-90-vh {
  height: 90vh !important;
}

.hs-w-95 {
  width: 95% !important;
}
.hs-w-120 {
  width: 120% !important;
}

.hs-w-97 {
  width: 97% !important;
}

.hs-w-98 {
  width: 98% !important;
}
.hs-w-100 {
  width: 100% !important;
}
.hs-w-200 {
  width: 200% !important;
}
.hs-h-7 {
  height: 7% !important;
}
.hs-h-10 {
  height: 10% !important;
}
.hs-h-60 {
  height: 60% !important;
}
.hs-h-65 {
  height: 65% !important;
}
.hs-h-75 {
  height: 75% !important;
}
.hs-h-40 {
  height: 40% !important;
}

.hs-vh-75 {
  height: 75vh !important;
}

.hs-w-25-rem {
  width: 25rem !important;
}

.hs-mnw-25r {
  min-width: 25rem;
}

.hs-h-text-area {
  height: 17rem !important;
}

.hs-h-whatsapp-preview-area {
  height: 25rem !important;
}

.hs-min-h-10-vh {
  min-height: 10vh !important;
}
.hs-h-2-7-rem {
  height: 2.7rem !important;
}
/* ############################## CUSTOM PADDINGS AND MARGINS ############################## */

.hs-px-1 {
  padding-left: var(--hs-padding-1);
  padding-right: var(--hs-padding-1);
}
.hs-mb-0 {
  margin-bottom: 0px !important;
}

/* ############################## CUSTOM CSS ############################## */

.saved-template-select {
  width: 120px !important;
  height: 29px !important;
  background-color: #f4f4ff !important;
  border-radius: 8px !important;
  cursor: pointer !important;
}

.avatar-small-round {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-big-round {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.profilePopup > .ant-dropdown-menu-root {
  left: -110px;
  position: absolute;
}

input::placeholder {
  font-size: 15px !important;
}

textarea::placeholder {
  font-size: 15px !important;
}

.bs-vr-override {
  opacity: 1 !important;
  width: 1px !important;
}

.selectBoxSearchInput,
.selectBoxSearchInput:hover,
.selectBoxSearchInput:focus-within {
  border-bottom: 1px solid #e6e6e6;
}

.cursor-pointer {
  cursor: pointer !important;
}

.hs-hr {
  border-top: 0.9px solid #00001290;
}

.hs-w-fit {
  width: fit-content !important;
}

.hs-spin-360-infinite {
  animation: rotate-animation 1s cubic-bezier(0.25, 0.75, 0.75, 1) infinite;
}

@keyframes rotate-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.round-checkbox > * > .ant-checkbox-inner {
  border-radius: 50% !important;
}

.checkbox-checked-bg-violet > .ant-checkbox-checked > .ant-checkbox-inner {
  background-color: var(--hs-violet);
}

.hs-input-box {
  min-height: 25px;
  outline: 1px solid #cfd3d4 !important;
  transition: none !important;
  border: none !important;
  border-radius: 5px !important;
  color: var(--hs-color-BlackRussian);
}

.hs-input-box:focus-within,
.hs-input-box > .ant-select-selector:focus-within {
  transition: none !important;
  outline: 2px solid var(--hs-violet) !important;
}

.hs-input-has-error,
.hs-input-has-error:focus-within,
.hs-input-has-error > .ant-select-selector,
.hs-input-has-error > .ant-select-selector:focus-within {
  outline: 1px solid var(--hs-red) !important;
  border-color: unset;
}

.hs-placeholder-text::placeholder,
.hs-placeholder-text > .ant-select-selector > .ant-select-selection-placeholder {
  color: var(--hs-placeholder-grey);
  font-weight: 400;
}

.hs-overflow-scroll {
  overflow: scroll;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none; /* Disable pointer events to make it non-clickable */
}

.content {
  padding-top: 10px;
}

.hs-bg-blur-3 {
  backdrop-filter: blur(3px);
}

.bg-transparent {
  background: transparent;
}

.hs-drawer-open {
  transition: transform 0.3s ease-out;
  transform: translateX(0%);
}

.hs-drawer-closed {
  transition: transform 0.3s ease-out;
  transform: translateX(100%);
}

.hs-drawer-body {
  height: 100%;
  width: 97%;
}

.hs-text-decoration-none {
  text-decoration: none;
}

.hs-input-prefix > span.ant-input-prefix {
  width: 100%;
  display: inline;
  margin: auto;
}

.hs-input-prefix {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}

.hs-overflow-wrap-break-word {
  overflow-wrap: break-word;
}

#hs-checkbox-group-inner {
  border: none;
}

.hs-big-button {
  height: 47px !important;
}
.hs-xl-button {
  height: 54px !important;
  font-size: 18px !important;
}

.hs-med-button {
  height: 43px !important;
}

.hs-xs-button {
  height: 25px;
  width: auto;
}

.hs-s-button {
  height: 35px;
  width: auto;
  border-radius: 4px;
}

.hs-button-outline {
  background-color: transparent !important;
  color: var(--hs-violet);
  border: 1px solid var(--hs-violet) !important;
}

.hs-button-outline:hover {
  background-color: var(--hs-violet) !important;
  color: var(--hs-plain-white) !important;
}

.hs-text-outline {
  color: inherit;
}

.hs-button {
  border-color: transparent;
  box-shadow: none;
  transition: none;
}
.hs-button:hover {
  border-color: initial !important;
  box-shadow: none;
  transition: none;
}

.hs-button-group-row {
  width: 100% !important;
}

.hs-button-group-col {
  width: 100% !important;
  justify-content: center !important;
}

.hs-grid-justified-center {
  display: grid;
  width: 100%;
  justify-content: center;
  justify-items: center;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
}

.hs-card-preview {
  display: flex;
  justify-content: center;
}

.select-group-tag {
  background-color: rgb(245, 244, 255);
  color: rgb(96, 91, 255);
  border-radius: 12px;
  border: transparent;
  text-transform: capitalize;
}

.card-main-content {
  border-radius: 4px !important;
  width: 100% !important;
}

.card-main-shadow {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
}

.hs-saved-template-select > .ant-select-selector > .ant-select-selection-placeholder {
  color: var(--hs-violet);
}
.hs-saved-template-select > .ant-select-selector {
  padding-left: 0 !important;
}

.hs-saved-template-select > .ant-select-arrow {
  right: 0;
}

.card-buttons-container {
  border-radius: 12px !important;
  background: transparent !important;
}

.hs-max-w-20-rem {
  max-width: 20rem !important;
}

.hs-min-w-10-rem {
  min-width: 10rem !important;
}

.hs-mw-500-px {
  max-width: 500px !important;
}

.hs-floating-button-aside-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.hs-floating-button-aside {
  position: absolute;
  right: -25px;
}

.unset-button {
  background-color: transparent;
  border: none;
  padding: unset;
}

.hs-preview-container-bg {
  background-color: var(--hs-v-light-grey) !important;
}

.hs-preview-message-area-container {
  max-height: 20rem !important;
  overflow: auto;
}

.hs-select-show-only-popup {
  visibility: hidden;
  z-index: -10 !important;
  width: 0px !important;
  height: 0px !important;
  padding: unset;
  margin: unset;
  position: absolute;
}

.hs-button-link {
  color: var(--hs-violet) !important;
}

.hs-d-if {
  display: inline-flex !important;
}

/* ############################## ICONS ############################## */

.closeIconSmall {
  background-position: 16% 1.5%;
  border: none;
  background-color: transparent;
}

.textsms-auth-card-icons {
  height: 52px !important;
  width: 49px !important;
}

.closeIconBig {
  height: 24px !important;
  width: 24px !important;
  background-position: 16% 1.45%;
  background-size: 42rem;
}
.closeIconBlack {
  height: 24px !important;
  width: 24px !important;
  background-position: 16% 1.3% !important;
  transform: scale(0.8);
}
.hs-delete-icon {
  height: 70px !important;
  width: 68px !important;
  background-position: -6.4rem -26.7rem;
  background-size: 45rem;
  transform: translate(2%, 0%) scale(0.7);
}

.hs-delete-icon-small {
  background-position: -17.8rem -8.3rem;
}

.pauseIconBig {
  width: 76px !important;
  height: 57px !important;
  background-position: 16.8% 44%;
}
.campaignEdit {
  width: 62px !important;
  height: 57px !important;
  background-position: 94.8% 44%;
}

.info-icon {
  background-position: 1.8% 55.5%;
}

.campaign-stop-icon {
  width: 62px !important;
  height: 57px !important;
  background-position: -20rem -28.1rem;
}

.alert-modal-icon {
  width: 62px !important;
  height: 57px !important;
}

.disConnectIcon {
  height: 70px !important;
  background-position: 80.9% 43.3%;
  width: 56px !important;
}
.addAuthorizedIcon {
  height: 70px !important;
  background-position: 89.1% 30.5%;
  width: 35px !important;
}
.zoho-connect-arrow {
  height: 24px !important;
  background-position: 83.1% 26.6%;
  width: 35px !important;
}
.zoho-connect-messaging {
  height: 30px !important;
  width: 58px !important;
  background-position: 37.2% 83.8%;
  border-radius: 37px;
}
.zoho-connect-campaign {
  height: 30px !important;
  background-position: 45.4% 83.8%;
  border-radius: 37px;
}
.zoho-connect-automation {
  height: 30px !important;
  width: 53px !important;
  background-position: 54.3% 83.8%;
  border-radius: 37px;
}
.revokeIcon {
  background-position: 82.2% 30.8%;
  height: 56px !important;
}

.documentIconBlue {
  height: 30px !important;
  background-position: 47.5% 32.3%;
  width: 30px !important;
}

.photosIconBlue {
  height: 30px !important;
  background-position: 47.5% 26.3%;
  width: 30px !important;
}

.profileMoreMenuIcon {
  background-position: 35% 18%;
}

.fromNumberDropDownIcon {
  background-position: 0.8% 0.7%;
  transform: translate(2%, 0%) scale(0.8);
}
.headerDropDownIcon {
  background-position: 2.1% 0.7%;
  transform: translate(2%, 0%) scale(0.8);
}
.helloSendLogoWithText {
  background-position: -4.9% 91.2%;
  width: 15% !important;
}
.helloSendLogoBuyNumber {
  background-position: 2.9% 89%;
  width: 30% !important;
  height: 28px !important;
}
.pricingFeatureIcon {
  background-position: 68.2% 55.98% !important;
  height: 22px !important;
  width: 22px !important;
  background-size: 31rem !important;
}
.pricingFeatureComingSoonIcon {
  background-position: 62% 75.38% !important;
  height: 22px !important;
  width: 31px !important;
  background-size: 30.9rem 36.3rem;
}
.pricingGetStartedIcon {
  background-position: 73.7% 55.78% !important;
  background-size: 31rem 35rem;
  height: 22px !important;
  width: 22px !important;
  margin-right: 8px;
  color: var(--hs-plain-white);
}
.pricingExpandedFalse {
  background-position: 88.5% 56.5% !important;
  height: 70px !important;
  width: 22px !important;
}
.pricingExpandedTrue {
  background-position: 88.5% 67.5% !important;
  height: 70px !important;
  width: 22px !important;
}
.infoIconViolet {
  background-position: 64% 50.7%;
}

.infoIconRed {
  background-position: 70% 50.7%;
}

.hs-fw-bolder {
  font-weight: bolder;
}
.search-icon {
  background-position: 55% 9.5% !important;
}

.custom-anchor {
  color: var(--hs-violet);
  text-decoration: none;
}
.custom-anchor:hover {
  color: var(--hs-violet);
  opacity: 80%;
}

.whatsapp-banner {
  height: 2.5rem;
  background-position: -1rem -2.2rem;
  width: 18rem;
}

.whatsapp-business-icon-small {
  height: 20px !important;
  width: 20px !important;
  background-position: -8.9rem -7.25rem !important;
  background-size: 18rem !important;
}

.supportMan {
  background-position: 3% -1%;
  height: 8.2rem;
  aspect-ratio: 1/1;
  background-size: 30rem;
}

.infoModalIcon {
  height: 60px !important;
  width: 60px !important;
  background-position: 56.5% 44%;
}

.successInfoIcon {
  background-position: -19.21rem -17.55rem;
}

.Whatsapp-badgeIcon {
  background-position: -18.7rem -14.8rem !important;
}

.whatsapp-doodle-bg {
  background-image: url("../images/whatsapp-doodle-bg.png") !important;
}

.template-service-indicator-icon {
  background-size: 20rem !important;
}

.template-service-indicator-icon-whatsapp {
  background-position: -9.9rem -8rem;
}

.contact-service-indicator-icon {
  background-size: 13.1rem !important;
}

.contact-service-indicator-icon-twilio {
  background-position: -10.44rem -3.96rem;
}

.contact-service-indicator-icon-ringcentral {
  background-position: -3.86rem -2.9rem;
  background-size: 10rem !important;
}

.contact-service-indicator-icon-whatsapp {
  background-position: -6.45rem -5.3rem;
}
.contact-service-indicator-icon-hello_send {
  background-position: -4.42rem -0.97rem;
  transform: scale(0.8);
}
.contact-service-indicator-icon-teams {
  background-position: -6.4em -11.45em;
  transform: scale(0.8);
}

.whatsapp-color {
  color: var(--whatsapp-color) !important;
}

.hs-link {
  cursor: pointer !important;
  color: var(--hs-link-color) !important;
  text-decoration: none !important;
}

.hs-text-color {
  color: var(--hs-dark-grey) !important;
}

.hs-onboarding-modal-bg {
  background-size: 120rem;
  background-position: 87% 17%;
  height: 100% !important;
  width: auto;
}

/* ############################## SPRITES ############################## */
.actionIconsSprite {
  height: 27px;
  width: 25px;
  background-image: url("../svg/action-icons.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}

.actionIconsSpriteForSideBar {
  height: 40px;
  width: 30px;
  background-image: url("../svg/action-icons.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}

.staticIconsSprite {
  height: 25px;
  min-width: 25px;
  width: 25px;
  background-image: url("../svg/static-icons.svg");
  background-repeat: no-repeat;
}

.banners-sprite {
  background-image: url("../svg/banners.svg");
  background-repeat: no-repeat;
}

.cardsSprite {
  background-image: url("../svg/cards.svg");
  background-repeat: no-repeat;
}

.customComponentDropDownIcon {
  background-position: 0% 1%;
}
.generalSettingsIcon {
  background-position: -1rem -32.05rem;
  height: 25px !important;
}
.templateSettingsIcon {
  background-position: -4rem -32.1rem;
  height: 25px !important;
}
.usersSettingsIcon {
  background-position: -7.1rem -32.03rem;
  height: 25px !important;
}
.channelsSettingsIcon {
  background-position: -13.3rem -32.03rem;
  height: 25px !important;
}
.settingsHover {
  background-color: var(--hs-v-light-grey);
}
.blockedNumbersIcon {
  background-position: -30rem -35.23rem;
  height: 25px !important;
}
.blockedNumbersWhiteIcon {
  background-position: -32.8rem -35.03rem;
  height: 25px !important;
  transform: translate(2%, 0%) scale(0.8);
}
.blockedNumberSearchIcon {
  background-position: 54.8% 7.9%;
  margin-right: 5px;
  background-size: 27rem;
  width: 21px !important;
}
.settingsHover:hover {
  background-color: #f5f5f5;
}

/* ::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #e2e8f0;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e2e8f0;
} */
.boxShadowRemove {
  box-shadow: none !important;
}
.boxShadowRemove .ant-space-gap-col-small {
  column-gap: 5px !important;
}
.unsaved-modal-icon {
  background-position: 30.5% 55.5%;
  height: 65px !important;
  width: 65px !important;
  cursor: default;
}

#hs-label-and-value:has(.ant-form-item-has-error) {
  color: green !important;
}

.interFontWeightMedium {
  font-weight: 500;
}

.announcement {
  height: 21px !important;
  background-position: 53.6% 75.7%;
  width: 19px !important;
  transform: translate(2%, 0%) scale(1);
}
.announcement .ant-scroll-number-only-unit {
  font-size: x-small;
}

.announcementHover:hover {
  background-color: #f5f5f5;
  color: black !important;
}

.ant-table-body {
  scrollbar-color: #f0f0f0 #f0f0f0;
}
.conversationInbox .overflow-auto {
  scrollbar-color: #f0f0f0 #f0f0f0;
}

.campaignOverviewTable .ant-table-tbody {
  scrollbar-color: #f0f0f0 #f0f0f0;
}
.gray40 {
  color: #666666 !important;
}
.hs-btn-default.ant-btn-default {
  border-color: var(--hs-violet);
  color: var(--hs-violet);
}
.hs-btn-default.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: var(--hs-violet);
  color: var(--hs-violet);
}
.hs-default-list-scroll {
  overflow-y: auto;
  transition: 0.3s all;
}

.hs-default-list-scroll {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.hs-default-list-scroll:hover {
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
}

#nostepsauthpage-app-description {
  width: 90%;
}

.hs-text-primary {
  color: inherit;
}

.hs-button-primary {
  background-color: var(--hs-violet);
  color: var(--hs-plain-white) !important;
  border-color: transparent !important;
}

.hs-button-primary:hover {
  background-color: var(--hs-violet-hover) !important;
}

.hs-h-55-px {
  height: 55px !important;
}

.hs-h-80-px {
  height: 80px;
}

.inbox-header {
  height: 7% !important;
  min-height: 60px !important;
}

.hs-min-h-50-px {
  min-height: 50px !important;
}

.inbox-contact-search {
  height: 6% !important;
  min-height: 46px !important;
}

.hs-h-84 {
  height: 84% !important;
}

.hs-scale-0-8 {
  scale: 0.8 !important;
}

.hs-fs-small {
  font-size: small !important;
}

.hs-fs-25 {
  font-size: 25px;
}

.message-area-focus-border {
  margin-top: 0.2rem;
  box-shadow: 0 -1px 0 var(--hs-container-outline-grey);
}

.message-area-focus-border:focus-within {
  box-shadow: 0 -2px 0 var(--hs-violet);
}

.hs-word-break-keep-all {
  word-break: keep-all !important;
}

.hs-button-v-cancel {
  background-color: transparent;
  border: 1px solid var(--hs-button-outline-light-grey) !important;
  color: var(--hs-button-text-grey) !important;
}
.hs-button-v-cancel:hover {
  border: 1px solid var(--hs-button-outline-light-grey) !important;
  color: var(--hs-button-text-grey) !important;
}

.select-variant-borderless {
  outline: transparent !important;
}

.ant-table-cell > .template-item-label:hover .savedTemplateEye {
  visibility: visible !important;
}

.ant-select-item-option-content > .template-item-label:hover .savedTemplateEye {
  visibility: visible !important;
}

.hs-scroll-bar {
  scrollbar-color: var(--hs-scrollbar-color) var(--hs-scrollbar-color);
}

.hs-text {
  color: var(--hs-color-BlackRussian) !important;
}

.hs-text-secondary {
  color: var(--hs-color-medium-dark);
}

.hs-slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.hs-min-h-2-5-rem {
  min-height: 2.5rem !important;
}

.alert-icon-white {
  background-position: -37.3em -29.39em;
  height: 30px !important;
  width: 30px !important;
  background-size: 45rem;
}
.hs-min-h-100-vh {
  min-height: 100vh !important;
}

.hs-border-bottom {
  border-bottom: 1px solid var(--hs-container-outline-grey);
}
.hs-border-top {
  border-top: 1px solid var(--hs-container-outline-grey);
}

.hs-h-92 {
  height: 92% !important;
}

.hs-max-h-50-px {
  max-height: 50px;
  height: 100%;
}

.hs-max-h-100-px {
  max-height: 100px !important;
  height: 100%;
}

.hs-h-30 {
  height: 30% !important;
}

.hs-h-50 {
  height: 50% !important;
}
em-emoji-picker {
  height: 50dvh !important;
  width: 100% !important;
}

@media (max-width: 375px) {
  em-emoji-picker {
    width: 178px !important;
  }
}

@media (min-width: 376px) and (max-width: 480px) {
  em-emoji-picker {
    width: 240px !important;
  }
}

.add-blue-icon {
  height: 70px !important;
  width: 70px !important;
  background-position: -395px -439px;
}

.hs-h-45 {
  height: 45% !important;
}
.hs-h-20 {
  height: 20% !important;
}

.hs-min-h-50-dvh {
  min-height: 50dvh !important;
}

.hs-text-white-space-pre-wrap {
  white-space: pre-wrap !important;
}

.hs-max-h-40-dvh {
  max-height: 40dvh !important;
}
.page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  min-height: 100dvh;
}
@media (min-width: 768px) {
  .page-overlay {
    position: relative;
    z-index: 0;
    min-height: auto;
  }
  .page-content {
    height: auto;
  }
  .settingsHover {
    background-color: transparent;
  }
}

.page-header {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 8%;
}

.page-content {
  height: 92%;
}
.hs-max-h-250-px {
  max-height: 250px !important;
}
.hs-max-w-300-px {
  max-width: 300px !important;
}
.back-icon {
  rotate: 180deg;
  background-position: 70.7% 32.1%;
}

.preview-text {
  position: relative;
  display: inline-block;
  color: var(--hs-violet);
}

.preview-text.with-dot::after {
  content: ""; /* Empty content to create the dot */
  position: absolute;
  right: -5px; /* Position it relative to the "w" */
  top: 1px;
  background-color: red;
  width: 8px; /* Size of the dot */
  height: 8px;
  border-radius: 50%;
}

.hs-h-85-dvh {
  height: 85dvh !important;
}

.preview-container-height {
  height: var(--preview-container-height) !important;
}

.preview-absolute-container-height {
  top: calc(-1 * var(--preview-container-height)) !important;
}

.hs-min-w-350 {
  min-width: 350px;
}
.hs-min-w-30-px {
  min-width: 30px;
}
.right-arrow-icon {
  min-height: 25px !important;
  min-width: 25px !important;
  background-position: 59.7% 9.5%;
  transform: scale(1.5);
}
.hs-max-h-400-px {
  max-height: 400px !important;
}
.hs-max-w-fit-content {
  max-width: fit-content !important;
}
.hs-border-inline-end-0-px {
  border-inline-end: 0px !important;
}

.hs-max-w-40-px {
  max-width: 40px !important;
}

.hs-max-w-70 {
  max-width: 70% !important;
}

.add-block-number .ant-input:focus-within,
.add-block-number .ant-input-affix-wrapper:focus-within {
  border-color: #605bff !important;
  box-shadow: none !important;
}

.add-block-number .ant-input:hover,
.add-block-number .ant-input-affix-wrapper:hover {
  border-color: #605bff !important;
}

.unBlockIcon {
  background-position: -33.95rem -27.45rem;
  min-width: 25px;
  height: 25px !important;
}
.confirmDeleteIcon {
  background-position: -32.55rem -30.15rem;
  min-width: 59px;
  height: 63px !important;
}
.hs-max-w-200-px {
  max-width: 200px !important;
}
.hs-max-w-400-px {
  max-width: 400px !important;
}
.hs-h-43-px {
  height: 43px !important;
}
.antd-loading-icon {
  color: var(--hs-violet) !important;
}
