body {
  font-family: "Inter", sans-serif, sans-serif;
}
.savedTempTable thead .ant-table-cell {
  background-color: #fff !important;
  /* color: white !important; */
  font-size: 12px;
  font-weight: 400 !important;
}
.savedTempTable .ant-table-container {
  box-shadow: none;
}
.savedTempTable.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 14px 8px;
}
.savedTempTable.ant-table-wrapper table {
  border: none;
}
.savedTempTable .ant-table-container {
  padding: 2rem;
  margin-top: 15px;
}
.savedTempTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}
.savedTempTable.ant-table-wrapper .ant-table-thead > tr > th {
  /* text-align: center; */
  padding: 16px 8px;
  white-space: break-spaces;
}
.savedTempTable .ant-table-tbody > tr > td {
  /* text-align: center; */
  width: 3rem;
  max-width: 3rem;
  font-family: "Inter", sans-serif;
  cursor: default !important;
  /* color: #6a707e; */
}
.savedTempTable .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: inherit;
}
.savedTempTable.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 0px;
}
.savedTempTable.ant-table-wrapper .ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 1px solid #ededed !important;
}

.templatesContent.ant-input {
  border-radius: 0%;
  min-height: 5rem;
  max-height: 20rem;
  height: 15rem;
}

.addTempBtn {
  font-weight: 500;
  font-size: 16px;
  border: none;
  background-color: #605bff !important;
  color: #fff !important;
  border-radius: 8px !important;
  height: 44px;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .addTempBtn {
    height: 36px;
    min-width: 120px;
    font-size: 13px;
  }
}

.confirmDeleteBtn {
  font-weight: 600;
  font-size: 16px;
  border: none;
  background-color: #e71d36 !important;
  color: #fff !important;
  border-radius: 8px !important;
  height: 44px;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#friendlyNameinput {
  width: 13rem;
  height: 2.5rem;
}
#friendlyName {
  font-family: "Inter", sans-serif;
}
.templatesAddButton {
  text-align: center;
}
.templatesAddButton.ant-btn.ant-btn-lg {
  border-radius: 4px;
}
#templateAdd {
  background-color: #109cf1;
  width: 7rem;
  height: 2.5rem;
  margin-right: 1.7rem;
  margin-top: 0.5rem;
}
#fdnameError,
#fdtemplateError,
#fdnumberError,
#fdeventError {
  color: #ee3248;
  font-size: 10px;
}
#contentError {
  color: #ee3248;
  margin-left: 1rem;
  font-size: 10px;
}
#friendlyNameId {
  font-size: 10px;
}
.workFlowDrawer .ant-drawer-header-title {
  flex-direction: row-reverse !important;
}

.workFlowTemp {
  max-height: 36.5rem;
  overflow-y: auto;
  margin: 1rem;
  border: 1px solid #d0d0d0;
}
.workFlowTemp.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: transparent;
}
.workFlowTemp.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 0px;
}
.workFlowTemp.ant-table-wrapper .ant-table-container {
  margin-top: 1rem;
  margin-right: 1rem;
}

.workFlowTemp.ant-table-wrapper .ant-table-thead > tr > td {
  border-bottom: 0px;
  background-color: transparent;
}
.workFlowTemp.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}

.templateDrawer .ant-drawer-header-title {
  flex-direction: row-reverse !important;
}

.copiedWorkFlowData {
  position: absolute;
  top: 0rem;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 3px 6px;
  border-radius: 10px;
  font-size: 14px;
}

.tempIcons {
  width: 11px;
  height: 9px;
  background-repeat: no-repeat;
  background: url("../images/icons.png");
  background-size: 162px 81px;
  transform: translate(0%, 0%) scale(3);
  border-radius: 37px;
}

.workflow-icon {
  background-position: 39.8% 19%;
  border-radius: 37px;
  height: 28px !important;
  width: 28px !important;
}
.workflow-icon:hover {
  background-color: #e9e8ff;
}
.deleteTemplate-icon {
  background-position: 26.55% 9.66%;
  border-radius: 37px;
  height: 28px !important;
  width: 28px !important;
}
.deleteTemplate-icon:hover {
  background-color: #fff4f5;
}
.editTemplate-icon {
  background-position: 45.7% 19.52%;
  border-radius: 37px;
  height: 28px !important;
  width: 28px !important;
}
.editDeleteSettingsIcon {
  background-position: 68% 44.28% !important;
}

.editTemplate-icon:hover {
  background-color: #fffbef;
}
.copyWorkFlowData-icon {
  background-position: -277px -60.9px;
}
.listFields {
  height: 40px;
}
.listFields:hover {
  background-color: #fafafa;
}
.search-icon {
  background-position: 12% 5.5%;
  border-radius: 37px;
}
.templateAdd {
  background-color: #605bff;
  color: #fff;
  width: 90%;
  margin: auto;
}
.evenListFields {
  position: relative;
}

.evenListFields::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 90%;
  border-bottom: 0.5px solid #f3f3f3;
  border-right: 0.5px solid #f3f3f3;
}
.oddListFields {
  position: relative;
}

.oddListFields::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80%;
  border-bottom: 0.5px solid #f3f3f3;
  border-left: 0.5px solid #f3f3f3;
}
.oddListFields {
  border-left: 0.5px solid #f3f3f3;
  border-bottom: 0.5px solid #f3f3f3;
}

.templateFieldsHeader {
  background: #e7e6ff;
  border-radius: 40px;
  color: #605bff;
  text-align: center;
}

.templateFieldBorder .ant-list-header {
  border-bottom: 0px;
}
.list-container-temp {
  max-height: 235px;
  overflow: auto;
}
.templateDrawer .list-container-temp {
  max-height: 150px !important;
}
.templateListItem {
  margin-block-end: "0px";
  border-top: "0px";
  border-bottom: "0.5px solid #f3f3f3";
  border-right: "0.5px solid #f3f3f3";
  padding: "8px";
}
.truncate-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Ensure it respects the width of the parent element */
}
.editDeleteSettingDropdown {
  /* inset: 291.648px auto auto 1228.37px !important; */
  width: 9pc !important;
}

.editDeleteSettingDropdown .ant-dropdown-menu {
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.08), 0 20px 20px rgba(0, 0, 0, 0.08) !important;
}
.savedTempTable .ant-table-cell {
  cursor: text;
}
.savedTempTable .ant-table-body {
  height: 68vh !important;
}
.eyeIcon {
  visibility: hidden; /* Hide the icon by default */
}

.ant-table-tbody > tr:hover .eyeIcon {
  visibility: visible; /* Show the icon on row hover */
}
.previewTemplateEyeIcon {
  background-position: 67.5% 18.8%;
  height: 20px !important;
}

.templateModal {
  width: 100%;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
}

.templateModalContent {
  width: 95%;
}
.savedTemplateSelect {
  min-width: 100px;
  height: 29px;
  border-radius: 8px;
  cursor: "pointer";
}
@media (max-width: 768px) {
  .savedTemplateSelect {
    width: 122px !important;
  }
}
