.PricingPage {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.pricingCard .ant-card-body {
  padding-bottom: 0px !important;
  padding-top: 15px !important;
  margin-right: -15px;
}
.pricingCard .ant-card-head {
  margin-right: -15px;
}

.over {
  background: linear-gradient(to right, #009efd, #40c3f7);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.successPricingModel {
  width: 400px !important;
  height: 416px;
}

.featureList li {
  padding-top: 14px;
  font-weight: 400;
  font-size: 12px;
  color: #486581;
}
.featureList li:empty {
  display: none;
}

.inner-1 {
  border-right: 1px solid black;
}
.right {
  width: 50px;
}
.left,
.right {
  height: auto;
  overflow-y: scroll;
}

.left {
  border-right: 1px solid black;
}

.App-link {
  color: #61dafb;
}

.featureList {
  list-style-type: none;
  padding-left: 0rem;
}

.oappsLogo {
  height: 28px;
  width: 92px;
  margin-top: 15px;
  margin-left: 53px;
  margin-bottom: 15px;
}
.getStarted {
  background-color: #009efd;
  width: 248px;
  height: 48px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 25px;
}
.pricingRow .ant-row {
  flex-flow: inherit;
}

#components-collapse-demo-collapsible .ant-space {
  width: 100%;
}

.PricingPart {
  position: fixed;
  padding-top: 75px;
  padding-right: 5px;
}
.FAQTitle {
  font-weight: 700;
  font-family: inter;
  font-size: 32px;
}

.FAQIcon {
  position: fixed;
}

.pricingCard {
  width: 331px;
  height: 80dvh;
  padding-top: 5px;
}

@media (max-width: 750px) {
  .pricingContent {
    width: 400px !important;
  }
  .PricingPart {
    position: relative;
    margin: auto;
  }
  .pricingContent {
    flex-direction: row;
  }
  .pricingRow {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  .FAQRow {
    padding-left: 15px !important;
    margin-left: 15px !important;
  }
  .FAQIcon {
    display: none;
  }
  #FAQ {
    padding-left: 0vh;
  }
  .FAQTitle {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }
  .FAQDiv {
    margin-left: 0px !important;
  }
  .over {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }
  .featureList li {
    font-size: 25px !important;
  }

  .getStarted {
    margin-right: 30%;
  }
  .pricingCard {
    width: 100% !important;
    height: auto;
    left: 0px;
    padding-top: 5px;
  }
  .Yearly {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
  .pricingCard .ant-card-body {
    padding-left: 15% !important;
  }
  .listTitle {
    font-size: 35px !important;
  }
  .pricingCol {
    margin-right: 0px !important;
    width: 100% !important;
  }
  .Yearly .ant-row {
    display: block;
  }
  .Yearly .ant-row .ant-col .ant-space {
    display: block;
  }
  .checkMark {
    width: 25px;
  }
}

.getStarted:hover,
.getStarted:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.5em);
  background-color: #009efd !important;
}

.card {
  border-radius: 10px;
  margin-bottom: 20px;
}

.card-body h5 {
  font-weight: bold;
  color: #111827;
}

.card-body p {
  color: #6b7280;
}

.card-body ul li {
  color: #111827;
  font-size: 16px;
  margin-bottom: 10px;
}
.pricingHeader {
  font-family: dm sans !important;
  position: fixed !important;
  height: 60px !important;
  z-index: 1 !important;
  top: 0 !important;
  padding-left: 0 !important;
}

.comparison-feature-column,
.comparison-monthly-column,
.comparison-yearly-column {
  display: flex;
  flex-direction: column;
}

.comparison-feature,
.comparison-monthly,
.comparison-yearly {
  padding: 14px 26px;
}

.comparison-feature {
  text-align: left;
}

.comparison-monthly,
.comparison-yearly {
  text-align: center;
  padding-bottom: 18.5px !important;
}

.comparison-feature-column .comparison-feature:first-child {
  margin-top: 40px !important;
}
.comparison-feature-column .comparison-feature {
  font-size: 16px;
  font-weight: 500 !important;
  padding-bottom: 16px;
}
.comparison-monthly-column .comparison-monthly:first-child {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 25px !important;
  border-bottom: none !important;
}
.comparison-monthly-column .comparison-monthly:first-child::after,
.comparison-monthly-column .comparison-monthly:last-child::after {
  background: none !important;
  box-shadow: none !important;
}
.comparison-yearly-column .comparison-yearly:first-child {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 23px !important;
  border-bottom: none !important;
}
.comparison-yearly-column .comparison-yearly:first-child::after,
.comparison-yearly-column .comparison-yearly:last-child::after {
  background: none !important;
  box-shadow: none !important;
}
.comparison-yearly-column {
  border: 2px solid #605bff !important;
  border-radius: 20px;
  box-shadow: rgba(96, 91, 255, 0.1) 0px 5px 10px 10px;
}
.pricingPageHomeBackground {
  background-image: url("./assets/svg/PricingPageHomeBackground.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.comparison-monthly,
.comparison-yearly {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  position: relative;
}

.comparison-monthly::after,
.comparison-yearly::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50;
  width: 50%;
  height: 2%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}
@keyframes expandAndShrink {
  0% {
    box-shadow: 0px 0px 0px rgba(96, 91, 255, 0);
  }
  50% {
    box-shadow: 0px 0px 15px rgba(96, 91, 255, 0.8);
  }
  100% {
    box-shadow: 0px 0px 10px rgba(96, 91, 255, 0.6);
  }
}

.expand-outline {
  /* box-shadow: 0px 0px 10px rgba(96, 91, 255, 0.6) !important;
  animation: expandAndShrink 0.5s ease-out; */
  outline: 2px solid #605bff !important;
}

@media (max-width: 768px) {
  .comparison-feature-column {
    max-width: 40% !important;
    flex: none !important;
  }
  .comparison-feature {
    font-size: 14px !important;
    padding-bottom: 20px !important;
  }
  .comparison-monthly-column {
    max-width: 27% !important;
    flex: none !important;
    padding: 0px !important;
  }
  .comparison-yearly-column {
    max-width: 36% !important;
    flex: none !important;
  }
  .pricingFeatureIcon {
    background-size: 21rem 25rem;
  }
}

@media (max-width: 480px) {
  .comparison-feature-column {
    max-width: 40% !important;
    flex: none !important;
    margin-top: 40px !important;
  }
  .comparison-feature {
    font-size: 10px !important;
    padding-bottom: 5px !important;
  }
  .comparison-monthly-column {
    max-width: 26% !important;
    flex: none !important;
    padding: 0px !important;
    margin: 0px !important;
  }
  .comparison-yearly-column {
    max-width: 26% !important;
    flex: none !important;
  }
  .pricingFeatureIcon {
    background-size: 21rem 25rem;
  }
  .monthlyPricingCard,
  .yearlyPricingCard {
    width: 100% !important;
    margin-bottom: 20px;
  }
}
.faq-item {
  background-color: #fff !important;
  border-radius: 12px !important;
  padding-top: 40px !important;
  padding-bottom: 25px !important;
  padding-left: 30px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-direction: column !important;
  /* box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important; */
  transition: box-shadow 0.3s ease-in-out !important;
}
.faq-item-body {
  margin-top: 5px !important;
}
.pricingHeadingLevel2 {
  text-align: center !important;
  color: #6b7280 !important;
}
.monthlyPlanLevel5 {
  color: #111827 !important;
}
.monthlyPlanGetStarted {
  border: 1px solid #605bff !important;
  color: #605bff !important;
}
.monthlyPlanGetStarted:hover {
  background-color: #605bff !important;
  color: white !important;
}
.yearlyPlanDiscount {
  color: #605bff !important;
}
.yearlyPlanCard {
  width: 350 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-top: 10px solid #605bff !important;
}
.yearlyCbItem {
  border: 1px solid #605bff;
  margin-top: 14px;
  background-color: #605bff;
  color: white;
}
.yearlyCbItem:hover {
  background-color: #4944de !important;
  color: white !important;
  border-color: #4944de !important;
}
.exploreFeatures {
  background-color: #f8f8f9;
  margin-top: 50px;
}
.goToServicePricingBtn {
  background-color: #605bff !important;
  border: none;
  color: #ffffff !important;
  border-radius: 10px;
  padding: 5px 5px;
}
