.fontStyle {
  font-family: "Inter", sans-serif, sans-serif;
  font-weight: 500;
  color: #474747;
}
.container-loading {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.activateLicenseModal.ant-modal {
  line-height: 1.4;
}

.activateLicenseModal {
  margin-left: 16px;
  margin-right: 16px;
}
.activateLicenseModal.ant-modal .ant-modal-content {
  padding: 26px 0px 20px 26px;
  border-radius: 10;
}
.modalTitle {
  font-size: 20px;
  margin-bottom: 0em;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
.modalDescription {
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

.clockIconInModal {
  font-size: 60px;
  color: #b2605e;
  vertical-align: baseline;
}
.activateLicenseBtn {
  margin-top: 8px;
}
.activateLicenseBtn :hover {
  color: white !important;
}
/* @media (max-width: 767px) {
  .activateLicenseBtn {
    padding-left: 3.5rem !important;
    margin-right: 1rem;
  }
} */

.activateExtendBtn {
  margin-top: 8px;
}
.activateExtendBtn :hover {
  color: black !important;
}
.activateBtn {
  background-color: #68bb5e;
  font-family: "Inter", sans-serif;
}
.activateBtn.ant-btn-primary:not(:disabled):hover {
  background-color: #85c393;
}
.activateLicensedBtn {
  background-color: #605bff;
  font-family: "Inter", sans-serif;
  color: white;
  border-color: #605bff;
}

@media (max-width: 600px) {
  .modalTitle {
    font-size: 18px;
  }
  .modalDescription {
    font-size: 15px;
  }
  .clockIconInModal {
    font-size: 40px;
    margin-top: 7px;
  }
}
.creditsModal {
  font-family: "Inter", sans-serif;
}
.exclamatoryIcon {
  font-size: 55px;
  color: red;
  margin-top: 10px;
}
.errorModalTitle {
  color: red;
  font-size: 24px;
  margin-bottom: 0em;
  font-family: "Inter", sans-serif;
}
.errorModalDescription {
  font-family: "Inter", sans-serif;
}
.errorMessageModal {
  margin-left: 20px;
}
@media (max-width: 600px) {
  .errorModalTitle {
    font-size: 18px;
  }
  .errorModalDescription {
    font-size: 14px;
  }
  .exclamatoryIcon {
    font-size: 40px;
    margin-top: 7px;
  }
}
.buyNumberCancel {
  height: 40px;
  width: 140px;
  border-radius: 12px;
}
.buyNumberCancel.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: var(--hs-violet);
}
.buyCreditsButton {
  background-color: var(--hs-violet);
  height: 40px;
  width: 140px;
  color: white;
  border-radius: 12px;
}
.creditsModalTitle {
  color: #d60000;
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 0.75rem;
}
.buyCreditsButton.ant-modal .ant-modal-content {
  padding: 20px;
}

.creditsModalContent {
  color: rgba(3, 2, 41, 0.5);
}
* {
  font-family: "Inter", sans-serif;
}

.buyNumberMenu {
  border-bottom: 0px;
  text-align: center;
}
.buyNumberMenuItem {
  width: 49%;
  color: var(--hs-color-BlackRussian) !important;
  font-weight: 800;
}
.creditsModal .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-bottom-color: #605bff !important;
  border-bottom-width: 3px;
}
.addChannel-btn {
  color: white;
  background-color: #605bff;
}
.fileUploadModal > .ant-modal-content > .ant-modal-close,
.sendSMSFileUploadModal > .ant-modal-content > .ant-modal-close {
  height: auto;
  width: auto;
}

.fileUploadModal > .ant-modal-content > .ant-modal-close:hover,
.sendSMSFileUploadModal > .ant-modal-content > .ant-modal-close:hover {
  background: none;
}

.fileUploadModal > .ant-modal-content {
  height: 372px;
  flex-shrink: 0;
}

.fileUploadModal {
  width: 430px !important;
}

.fileUploadModal .uploadSizeLimitWarning,
.sendSMSFileUploadModal .uploadSizeLimitWarning {
  color: rgba(3, 2, 41, 0.5);
  font-size: 12px;
}

.fileUploadModal .modalContent,
.sendSMSFileUploadModal .modalContent {
  row-gap: 20px;
}

.sendSMSFileUploadModal > .ant-modal-content {
  height: 320px;
  padding: 16px 24px;
  flex-shrink: 0;
}

.sendSMSFileUploadModal {
  width: 350px !important;
}

.max-width-90 {
  max-width: 90% !important;
}

.hs-delete-icon {
  cursor: pointer;
}

.binOutlined {
  background-position: -144.5px -4.6em;
  cursor: pointer;
}

.modalUploadIcon {
  background-position: 69.6% 5.23%;
  width: 18px !important;
}

.modalCloseIcon {
  background-position: 16% 1.2%;
}

.previewCloseIcon {
  height: 15px !important;
  width: 15px !important;
  background-position: 21% 14%;
}

.circleWrapper {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.previewMask:hover {
  background-color: #00000034;
}

.circleSkeletonLoading > svg {
  height: 25px !important;
}

.sendSMSButton {
  height: 30px;
  font-size: 12px !important;
}

.campaignButton {
  height: 40px;
  font-size: 14px;
}

.ant-modal .ant-modal-close:hover {
  color: transparent;
  background-color: transparent;
  text-decoration: none;
}
.modalCloseIcon-customField {
  height: 37px !important;
  width: 37px !important;
  background-position: 16% 1.5%;
}
.hs-btn-small {
  font-weight: 500;
  font-size: 14px;
  border: none;
  background-color: var(--hs-violet) !important;
  color: #fff !important;
  border-radius: 8px !important;
  height: 36px;
  min-width: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hs-btn-cancel-small {
  font-weight: 600;
  font-size: 16px;
  color: #344054 !important;
  border-radius: 8px !important;
  height: 36px;
  min-width: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hs-btn-cancel-small:hover {
  border-color: var(--hs-violet) !important;
  color: var(--hs-violet) !important;
}
.select-field-error {
  outline: 1.5px solid red !important;
}
.mediaIcon {
  background-position: 64.3% 1.4%;
  background-size: 40rem;
}

.mediaIcon:hover {
  background-position: 64.3% 5.52%;
  background-size: 40rem;
}

.customSelectSearchIcon {
  background-position: 54.8% 9.2%;
  margin-right: 5px;
  background-size: 30rem;
}

.customSelectPopup {
  box-shadow: 0px 0px 20px 4px rgba(191, 191, 191, 0.25);
  z-index: 9999;
}

.dropDownList > div.rc-virtual-list > div.rc-virtual-list-holder > * > * > .ant-select-item-option-selected {
  background-color: #f7f7ff;
}

.dropDownList {
  overflow: auto;
}
.licenseIcon {
  background-position: -10.25rem -26.44rem;
  background-size: 35.2rem;
  width: 61px !important;
  height: 53px !important;
}

.iframe-popup {
  padding-top: 10px;
  width: 100%;
  height: 70vh;
  background: white;
}
.notDataFound {
  min-height: 200px;
}
.empty-content {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 35%;
}
.confirmDeleteModal .ant-modal-close {
  display: none;
}
.subAccountListItem .ant-select-item-option-active {
  background-color: #fff !important;
}
.subAccountListItem .ant-select-item-option:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.campaignOverviewEdit .ant-modal-close {
  display: none;
}
.refreshIconHover:hover {
  background-color: #f5f5f5 !important;
  border-radius: 50px !important;
}
